import React from "react";

export default function PageFooter() {
  return (
    <div id="footer">
      <ul className="copyright">
        <li>&copy; Untitled. All rights reserved.</li>
        <li>
          Based on and forked from{" "}
          <a href="https://hachyderm.io/@nova">Kris Nóva's</a> hachyderm.io
          community guide:{" "}
          <a href="https://community.hachyderm.io">community.hachyderm.io</a>{" "}
          Theme:{" "}
          <a href="https://github.com/anubhavsrivastava/gatsby-starter-prologue">
            Gatsby Starter Prologue
          </a>
        </li>
      </ul>
    </div>
  );
}
